import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./components/homepage.jsx";
import Register from "./components/register.jsx";
import Upload from "./components/upload.jsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signup" element={<Register />} />
        <Route exact path="/upload" element={<Upload />} />
      </Routes>
    </Router>
  );
}

export default App;
