import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import '../styles/home.css';
import { wa, team, Raasta } from '../assets/index.js';
import axios from 'axios';
import { format } from 'date-fns';

const HomePage = () => {
    const [trips, setTrips] = useState([]);

    useEffect(() => {
        const fetchTrips = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/trips`);
                setTrips(response.data);
            } catch (error) {
                console.error('Error fetching trips:', error);
            }
        };

        fetchTrips();
        const interval = setInterval(fetchTrips, 30000);
        return () => clearInterval(interval);
    }, []);


    return (
        <div className='main-container'>
            <div className="header" id="home">
                <div className="nav">
                    <Link to="/">
                        <img src={Raasta} alt="logo" />
                    </Link>
                    <div id="link-wrapper">
                        <ul>
                            <li><ScrollLink to="home" smooth={true} duration={500}>Home</ScrollLink></li>
                            <li ><ScrollLink to="trips" smooth={true} duration={500}>Trips</ScrollLink></li>
                            <li ><Link to="/upload">Payments</Link></li>
                            <li ><ScrollLink to="team" smooth={true} duration={500}>Team</ScrollLink></li>
                            <li><ScrollLink to="contact" smooth={true} duration={500}>Contact Us</ScrollLink></li>
                        </ul>
                    </div>
                    <i className="fa-solid fa-bars toggle-menu"></i>
                </div>
                <div className="text">
                    <h3>Explore</h3>
                    <h2>Pakistan</h2>
                    <h3>With Us</h3>
                    <ScrollLink to="trips" smooth={true} duration={500}>Explore</ScrollLink>
                </div>
                <img src="cover.jpg" alt="Scenic view" className="header-image" />
            </div>
            <div id="trips">
                <h1>Upcoming Trips</h1>
                {trips.length === 0 ? (
                    <p className='nulltrips'>No trips right now</p>
                ) : (
                    <div className="tripscards">
                        {trips.map(trip => (
                            <div className="card" key={trip._id}>
                                <div id="cover">
                                    {trip.images && trip.images.length > 0 ? (
                                        <img src={trip.images[0].url} alt={trip.location} />
                                    ) : (
                                        <img src="/path/to/default/image.jpg" alt="default" />
                                    )}
                                </div>
                                <div className="details">
                                    <h2>{trip.location}</h2>
                                    <br></br>
                                    <p className="description">{trip.short_description}</p>
                                    <p><i className="fa-solid fa-calendar-days"></i>{format(new Date(trip.start_date), 'do MMMM yyyy')} - {format(new Date(trip.end_date), 'do MMMM yyyy')}</p>
                                    {trip.discount === 0 ? (<p><strong>Price {trip.price} PKR</strong></p>) :
                                        (
                                            <>
                                                <p>Original Price {trip.price} PKR</p>
                                                <p><strong>Discounted Price {trip.price - trip.discount} PKR</strong></p>
                                            </>
                                        )}
                                </div>
                                <div className="register hover:text-white">
                                    {trip.isLive ? <Link to={`/signup?_id=${trip._id}`}>Register</Link> : <h3>Cap Reached</h3>}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div id="team">
                <div id="about-wrapper">
                    <img src={team} alt="" id="about-image" />
                    <h1 className="about-text">SEE YOU ON THE NEXT ONE</h1>
                    <div className="shadow"></div>
                </div>
            </div>
            <div id="contact">
                <div id="contact-info-area">
                    {/*<h1>Contact Us</h1>*/}
                    <a aria-label="Chat on WhatsApp" href="https://wa.me/923027637393" target="_blank">
                        <img alt="Chat on WhatsApp" src={wa} id="whatsapp" />
                    </a>
                    {/*<form action="" method="POST">
                        <button type="submit" id="contact-button">Send</button>
                    </form>*/}
                </div>
                <footer>
                    <div id="foot-wrapper">
                        <div className="footer-content footer">
                            <h3>Follow Us</h3>
                            <Link to="https://www.facebook.com/raasta.com.pk" target="_blank"><p>Facebook</p></Link>
                            <Link to="https://www.instagram.com/raasta.com.pk/" target="_blank"><p>Instagram</p></Link>
                        </div>
                    </div>
                    <div className="footer-bottom footer trademark">
                        <p>© 2021 Raasta. All Rights Reserved.</p>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default HomePage;
