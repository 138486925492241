import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase/supabase';
import { useNavigate } from 'react-router-dom';

function ImageUpload() {
    const navigate = useNavigate();
    const [rollNumber, setRollNumber] = useState('');
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [image, setImage] = useState({
        image: null,
        extension: null,
    });
    const [uploading, setUploading] = useState(false);

    const verifyRollNumber = async () => {
        try {
            setError('');
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/verifyRollNumber?rollNumber=${rollNumber}`).then(res => {
                if (res.status == 200) {
                    return res.json();
                }
                else {
                    throw new Error('Booking not found');
                }
            });
            setName(response.name);

        } catch (error) {
            console.error('Error fetching trips:', error);
            setError(error.message);
        }
    }

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            //setImageExtension(event.target.files[0].name.split('.').pop());
            //setImage(URL.createObjectURL(event.target.files[0]));
            setImage({
                image: event.target.files[0],
                extension: event.target.files[0].type,
            })
        }
    };

    const handleUpload = async () => {
        try {
            setUploading(true);
            console.log(image.extension + "X" + image.image + " " + rollNumber + image.extension.split('/').pop())
            const { data, error } = await supabase
                .storage
                .from('payments')
                .upload(name.replace(/\s/g, '') + "_" + Date.now().toString(), image.image, {
                    upsert: true,
                    contentType: image.extension,
                })
            await fetch(`${process.env.REACT_APP_BACKEND_URL}/updatePaymentStatus?rollNumber=${rollNumber}`).then(res => {
                if (res.status == 200) {
                    return res.json();
                }
                else {
                    throw new Error('Error updating payment status');
                }
            });
            setUploading(false);
            onUpload();
        }
        catch (error) {
            alert('Error:', error.message);
        }
    };

    const onUpload = () => {
        alert('Payment uploaded successfully, your booking has been confirmed. Thank you for choosing Raasta. Redicrecting to homepage...');
        setTimeout(() => {
            navigate('/');
        }, 2000);
    }

    return (
        <div className='flex w-[100%] h-[100vh] items-center justify-center gap-20 flex-col'>
            <h1 className='text-[40px]'>Proof Of Payment (Upload)</h1>
            <div>
                {name == "" ?
                    (
                        <>
                            <h2 className='text-sm'>Enter your roll number to verify your booking.</h2>
                            <input type='text' placeholder='Enter Roll Number' className='border-2 border-black p-2 m-2' value={rollNumber} onChange={(e) => setRollNumber(e.target.value)} />
                            <button className='bg-black text-white p-2 m-2 rounded-md' onClick={verifyRollNumber}>Verify</button>
                            {error && <h2 className='text-sm text-red-500 text-thin'>{error}</h2>}
                        </>
                    ) :
                    (
                        <>
                            <h2 className='text-sm'>Welcome <span className='font-bold'>{name}</span>! Please upload your payment receipt to confirm booking.</h2>
                            <input type='file' className='border-2 border-black p-2 m-2' onChange={handleImageChange} />
                            {image.image && <img src={URL.createObjectURL(image.image)} alt='Preview' height={300} width={300} className='rounded-md' />}
                            <button className='bg-black text-white p-2 m-2 rounded-md' onClick={handleUpload} disabled={uploading}>
                                {uploading ? 'Uploading...' : 'Upload Image'}
                            </button>
                        </>
                    )
                }
            </div>

        </div>
    );
}
export default ImageUpload;