import React, { useState, useEffect, useMemo } from 'react';
import '../styles/register.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

const RegisterCustomer = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedTripId = searchParams.get("_id");
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false);
    const [trips, setTrips] = useState([]);

    const [formData, setFormData] = useState({
        fullName: '',
        cnic: '',
        email: '',
        phoneNumber: '',
        rollNumber: '',
        referralName: '',
        selectedTrip: selectedTripId
    });


    useEffect(() => {
        const fetchTrips = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/trips`);
                setTrips(response.data);
            } catch (error) {
                console.error('Error fetching trips:', error);
            }
        };

        fetchTrips();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const phoneRegex = /^\d{11}$/;
        const cnicRegex = /^\d{5}-\d{7}-\d{1}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!phoneRegex.test(formData.phoneNumber)) {
            alert('Phone number must be 11 digits.');
            return;
        }

        if (!cnicRegex.test(formData.cnic)) {
            alert('CNIC must be in the format xxxxx-xxxxxxx-x.');
            return;
        }

        if (!emailRegex.test(formData.email)) {
            alert('Invalid email address format.');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/bookings`, {
                fullName: formData.fullName,
                cnic: formData.cnic,
                email: formData.email,
                phone: formData.phoneNumber,
                rollNumber: formData.rollNumber,
                referralName: formData.referralName,
                tripId: formData.selectedTrip
            });

            if (response.status === 200) {
                setFormData({
                    fullName: '',
                    cnic: '',
                    email: '',
                    phoneNumber: '',
                    referralName: '',
                    selectedTrip: '',
                });

                alert('Booking successful! You will receive an email shortly with payment details.');
                navigate('/');
            } else {
                throw new Error('Error occurred during booking');
            }
        } catch (error) {
            console.error('Error during booking:', error);
            alert('Booking failed. Please try again.');
        }
    };

    return (
        <div className="signup-form">
            <form onSubmit={handleSubmit} className="form-horizontal">
                <strong className='hehe'>TRIP: {trips.find(trip => trip._id === selectedTripId)?.location}</strong>
                <div className='hehe'>
                    <h2 className='ghori'><span className='ghori' onClick={() => setToggle(false)}>Registration Form</span> | <span className='ghori' onClick={() => setToggle(true)}>View Itinerary</span></h2>
                </div>
                {toggle ? <div className="row">
                    <div className="col-8 offset-4">
                        <p>{trips.find(trip => trip._id === selectedTripId)?.itinerary.split('\n').map((item, key) => {
                            return <span key={key}>
                                {item.includes("Day") ? <b>{item}</b> : item}
                                <br />
                            </span>
                        })}</p>
                    </div>
                </div> : (
                    <>
                        <div className="form-group row">
                            <label className="col-form-label col-4">Full Name</label>
                            <div className="col-8">
                                <input type="text" className="form-control" name="fullName" placeholder="Full Name" value={formData.fullName} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-form-label col-4">CNIC</label>
                            <div className="col-8">
                                <input type="text" className="form-control" name="cnic" placeholder="CNIC" value={formData.cnic} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-form-label col-4">Email Address</label>
                            <div className="col-8">
                                <input type="email" className="form-control" name="email" placeholder="Email Address" value={formData.email} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-form-label col-4">Phone Number</label>
                            <div className="col-8">
                                <input type="text" className="form-control" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-form-label col-4">Roll Number</label>
                            <div className="col-8">
                                <input type="text" className="form-control" name="rollNumber" placeholder="Roll Number" value={formData.rollNumber} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-form-label col-4">Referral Name {(trips.find(trip => trip._id === selectedTripId)?.location.includes("LSE")) && <em>(1500 discount with valid referral)</em>}</label>
                            <div className="col-8">
                                <input type="text" className="form-control" name="referralName" placeholder="Referral Name" value={formData.referralName} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-8 offset-4">
                                <button type="submit" className="btn btn-primary btn-lg">Book Trip</button>
                            </div>
                        </div>
                    </>
                )}
            </form>
        </div >
    )
}

export default RegisterCustomer;
